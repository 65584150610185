import React from 'react';
import { Box, Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    width: '100%',
  },
  box: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const TextCellRenderer = (props) => {
  const classes = useStyles();

  return (
    <Tooltip className={classes.tooltip} title={props.value} placement="left-start" arrow>
      <Box className={classes.box}>{props.value}</Box>
    </Tooltip>
  );
};

export { TextCellRenderer };
