import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Typography,
  withTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GlobalState } from '../../GlobalState';
import { displayDateFormatter } from '../../lib/operators/displayDateFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { TextCellRenderer } from '../atoms';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';

const styles = (theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 'auto 8px auto 0',
  },
  titleContent: { display: 'inline-flex' },
  chip: {
    margin: '-1px 8px auto 0',
    color: theme.palette.primary.contrastText,
  },
});

class ProtectionProductsGridOrganismComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.setupStatics();
  }

  componentDidMount() {
    setTimeout(() => {
      this.dataGrid.api.sizeColumnsToFit();
    }, 2);
  }

  setupStatics = () => {
    this.frameworkComponents = {
      textRenderer: TextCellRenderer,
    };

    this.columnDefinitions = [
      {
        headerName: 'Contract',
        field: 'ContractNumber',
        width: 150,
        cellStyle: (params) => {
          if (params.data.DealershipOwnership === 'MINE') {
            return { color: this.props.theme.hendrick.dataDealershipIndicators.ownDealershipColor };
          }
          return { color: this.props.theme.hendrick.dataDealershipIndicators.otherDealershipColor };
        },
        filter: true,
        sortable: true,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.ContractNumber);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Contract',
        resizable: true,
      },
      {
        headerName: 'Status',
        field: 'ContractStatusDescription',
        width: 150,
        filter: true,
        sortable: true,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.ContractStatusDescription);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Status',
        resizable: true,
      },
      {
        headerName: 'Effective',
        field: 'EffectiveDate',
        valueFormatter: ({ value }) => displayDateFormatter(value),
        width: 150,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        comparator: gridDatetimeSortComparator,
        sortable: true,
        headerTooltip: 'Effective',
        resizable: true,
      },
      {
        headerName: 'Expiration',
        field: 'ExpirationDate',
        valueFormatter: ({ value }) => displayDateFormatter(value),
        width: 150,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        comparator: gridDatetimeSortComparator,
        sortable: true,
        headerTooltip: 'Expiration',
        resizable: true,
      },
      {
        headerName: 'Vehicle',
        field: 'VehicleFullDescription',
        cellRenderer: 'textRenderer',
        filter: true,
        sortable: true,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.VehicleFullDescription);
        },
        headerTooltip: 'Vehicle',
        resizable: true,
      },
      {
        headerName: 'Product',
        field: 'CoverageGroupDescription',
        cellRenderer: 'textRenderer',
        filter: true,
        sortable: true,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.CoverageGroupDescription);
        },
        headerTooltip: 'Product',
        resizable: true,
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
    };
  };

  render() {
    const { classes, data, expanded, onChange } = this.props;

    return (
      <ExpansionPanel expanded={expanded} onChange={onChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="protection-products-summary-content"
          id="protection-products-summary-header"
        >
          <div className={classes.titleContent}>
            <Typography variant="h6" className={classes.heading}>
              Protection Products Summary
            </Typography>
            <Chip label={data.length} color="primary" size="small" variant="outlined" className={classes.chip} />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.paper}>
            <div
              style={{ height: 300, width: '100%', minWidth: '580px' }}
              className={GlobalState.AppComponent.state.gridModeClass}
            >
              <AgGridReact
                ref={(c) => (this.dataGrid = c)}
                paginationAutoPageSize
                rowData={data}
                columnDefs={this.columnDefinitions}
                defaultColDef={this.defaultColDef}
                frameworkComponents={this.frameworkComponents}
                content={{ componentParent: this }}
                pagination
                rowHeight={25}
                onFirstDataRendered={this.onFirstDataRendered}
              />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export const ProtectionProductsGridOrganism = withStyles(styles)(withTheme(ProtectionProductsGridOrganismComponent));
