import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { BadgesCellRenderer, NamesCellRenderer, RankingNumberCellRenderer, TextCellRenderer } from '../atoms';
import { GlobalState } from '../../GlobalState';
import { displayDatetimeFormatter } from '../../lib/operators/displayDatetimeFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { gridTextToNumberComparator } from '../../lib/operators/gridTextToNumberComparator';
import { gridBadgeComparator } from '../../lib/operators/gridBadgeComparator';
import { LocalStorage } from '../../lib/storage/LocalStorage';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
});

class PropensityRankingTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.asaPermissions = new LocalStorage().get('asaPermissions');
    this.setupStatics();
  }

  componentDidMount() {
    this.dataGrid.api.sizeColumnsToFit();

    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  onRowSelection = (evt) => {
    const selected = evt.api.getSelectedRows();
    const leadId = selected[0].lead_identity_id;
    this.props.onLeadSelection(leadId);
  };

  setupStatics = () => {
    this.frameworkComponents = {
      badgesRenderer: BadgesCellRenderer,
      namesCellRenderer: NamesCellRenderer,
      rankRenderer: RankingNumberCellRenderer,
      textRenderer: TextCellRenderer,
    };

    this.onFirstDataRendered = (params) => {
      params.api.sizeColumnsToFit();
    };

    this.columnDefinitions = [
      {
        children: [
          {
            headerName: '#',
            field: 'ranking_position',
            cellRenderer: 'rankRenderer',
            sortable: true,
            width: 1,
            headerTooltip: '#',
            resizable: true,
          },
          {
            headerName: 'Lead',
            field: 'full_name',
            cellRenderer: 'textRenderer',
            filter: true,
            sortable: true,
            width: 160,
            headerTooltip: 'Lead',
            resizable: true,
          },
        ],
      },
      {
        headerName: 'Hendrick Relationships',
        children: [
          {
            headerName: 'Vehicles',
            field: 'sales_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'vehicle',
            sortable: true,
            width: 90,
            comparator: gridBadgeComparator,
            headerTooltip: 'Vehicles',
            resizable: true,
          },
          {
            headerName: 'Services',
            field: 'services_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'serviceLane',
            sortable: true,
            width: 81,
            comparator: gridBadgeComparator,
            headerTooltip: 'Services',
            resizable: true,
          },
          {
            headerName: 'Protection products',
            field: 'warranties_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'protectionProduct',
            sortable: true,
            width: 130,
            comparator: gridBadgeComparator,
            headerTooltip: 'Protection products',
            resizable: true,
          },
          {
            headerName: 'Unused redemptions',
            field: 'unredeemed_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'unusedRedemptions',
            sortable: true,
            width: 135,
            comparator: gridBadgeComparator,
            headerTooltip: 'Unused redemptions',
            resizable: true,
          },
        ],
      },
      {
        children: [
          {
            headerName: 'ASA',
            field: 'asa',
            sortable: true,
            width: 75,
            filter: 'agTextColumnFilter',
            cellRenderer: 'textRenderer',
            filterParams: { comparator: gridTextToNumberComparator },
            cellStyle: (params) => {
              if (this.asaPermissions.includes(params.value)) {
                return { color: this.props.theme.hendrick.asaIndicators.currentAsaColor };
              }
              return null;
            },
            valueGetter: (params) => {
              return completeEmptyOrNullFields(params.data.asa);
            },
            headerTooltip: 'ASA',
            resizable: true,
          },
          {
            headerName: 'Date of lead',
            field: 'last_activity_datetime',
            sortable: true,
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => displayDatetimeFormatter(value),
            filterParams: {
              comparator: gridDatetimeFilterComparator,
            },
            width: 117,
            comparator: gridDatetimeSortComparator,
            headerTooltip: 'Date of lead',
            resizable: true,
          },
          {
            headerName: 'Last activity',
            field: 'last_activity_description',
            filter: true,
            sortable: true,
            cellRenderer: 'textRenderer',
            valueGetter: (params) => {
              return completeEmptyOrNullFields(params.data.last_activity_description);
            },
            width: 150,
            headerTooltip: 'Last activity',
            resizable: true,
          },
        ],
      },
    ];
  };

  render() {
    const { classes, data } = this.props;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.paper}>
            <div style={{ height: 520, width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              {data && (
                <AgGridReact
                  ref={(c) => (this.dataGrid = c)}
                  paginationAutoPageSize
                  rowData={data}
                  columnDefs={this.columnDefinitions}
                  content={{ componentParent: this }}
                  rowSelection="single"
                  frameworkComponents={this.frameworkComponents}
                  pagination
                  rowHeight={38}
                  headerHeight={40}
                  onFirstDataRendered={this.onFirstDataRendered}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const PropensityRankingTemplate = withStyles(styles)(withTheme(PropensityRankingTemplateComponent));
