import * as React from 'react';
import { withTheme } from '@material-ui/core';

function RedemptionsBadgeOtherDealership(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 67.598 66.169" {...props}>
      <defs>
        <radialGradient
          id="gradient_redemptions_other_dealership"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientTransform="matrix(-1 0 0 1 1 0)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor={props.theme.hendrick.otherDealershipBadgeColor.light} />
          <stop offset={1} stopColor={props.theme.hendrick.otherDealershipBadgeColor.base} />
        </radialGradient>
      </defs>
      <path
        d="M76.31 30.784A16.827 16.827 0 0152.459 51.9L47.8 56.986l3.324 3.331 1.976-1.979a2.589 2.589 0 013.676 0l16.1 16.257a2.6 2.6 0 010 3.676l-7.352 7.346a2.6 2.6 0 01-3.676 0L45.755 69.353a2.6 2.6 0 010-3.676l1.807-1.807L44.4 60.7 22.139 85.017a5.191 5.191 0 01-7.352 0l-1.835-1.835a5.2 5.2 0 010-7.346l25.361-21.243L21.47 37.708l-5.318-.008L10 27.8l4.952-4.966 10.125 6.2.069 5.207 17.037 17.11 4.959-4.151A16.848 16.848 0 0167.1 21.624L56.184 32.391l9.18 9.18zM19.049 78.416a2.6 2.6 0 100 3.676 2.6 2.6 0 000-3.676z"
        transform="translate(-10 -20.374)"
        fill="url(#gradient_redemptions_other_dealership)"
      />
    </svg>
  );
}

export const RedemptionsBadgeOther = withTheme(RedemptionsBadgeOtherDealership);
