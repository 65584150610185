export function gridPercentComparator(valueA, valueB) {
  let a = null;
  let b = null;

  if (valueA) a = Number(valueA);
  if (valueB) b = Number(valueB);

  if (a === null) a = -1;
  if (b === null) b = -1;

  return a - b;
}
