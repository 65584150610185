/* eslint-disable no-restricted-globals */

export const displayDatetimeFormatter = (d) => {
  if (!d) return '-';

  let formattableDate = null;

  if (!d.getDate) {
    const timestamp = Date.parse(d);

    // Cannot be parsed. Return as is.
    if (isNaN(timestamp)) return d;

    formattableDate = new Date(timestamp);
  } else {
    formattableDate = d;
  }

  const day = `0${formattableDate.getDate()}`.slice(-2);
  const month = `0${formattableDate.getMonth() + 1}`.slice(-2);
  const year = `${formattableDate.getFullYear()}`;
  const hours = `0${formattableDate.getHours()}`.slice(-2);
  const minutes = `0${formattableDate.getMinutes()}`.slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
