import React, { PureComponent } from 'react';
import { withStyles, Button, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { GlobalState } from '../../GlobalState';

const styles = (theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
});

const preventDefault = (evt) => evt.preventDefault();

class SummarizedDataComponent extends PureComponent {
  componentDidMount() {
    this.dataGrid.api.sizeColumnsToFit();
  }

  render() {
    const { classes, columnsDefinition, items, title } = this.props;

    return (
      <Card>
        <CardHeader title={title} titleTypographyProps={{ variant: 'overline', component: 'h4' }} />
        <CardContent className={classes.cardContent}>
          <div className={GlobalState.AppComponent.state.gridModeClass} style={{ height: 160, width: '100%' }}>
            <AgGridReact
              ref={(c) => (this.dataGrid = c)}
              columnDefs={columnsDefinition}
              rowData={items}
              headerHeight={35}
              rowHeight={35}
            />
          </div>
          <CardActions className={classes.actions} disableSpacing>
            <Button
              size="small"
              href="/#/lead/1"
              onClick={preventDefault}
              variant="outlined"
              className={classes.button}
            >
              View more
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    );
  }
}

export const SummarizedData = withStyles(styles)(SummarizedDataComponent);
