import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: { display: 'inline-flex' },
  nochip: {
    margin: 'auto 10px auto 0',
  },
}));

function NoChipBase(props, component) {
  const classes = useStyles();
  const { badgeStyle } = props;
  return (
    <div className={classes.content}>
      <div className={classes.nochip} />
      <div style={badgeStyle}>{component}</div>
    </div>
  );
}

export { NoChipBase };
