import React, { PureComponent } from 'react';
import { withSnackbar } from 'notistack';
import { Paper, Grid } from '@material-ui/core';
import { PageLayout, RankingViewTemplate } from '../template';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { API } from '../../lib/xhr';
import { PageTitle, EmptyDataPlaceholder } from '../molecules';
import { DailyIcon, WeeklyIcon, MonthlyIcon, SearchSelectField } from '../atoms';

class RankingByDatePageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.notifier = new SnackbarVisitor(this.props);
    this.api = new API(this.notifier);

    this.urlSearchParams = new URLSearchParams(this.props.location.search);

    this.urlVehicleType = this.urlSearchParams.get('vehicleType');
    this.urlLeadType = this.urlSearchParams.get('leadType');

    this.vehicleTypeOptions = [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: 'NEW',
        label: 'New',
      },
      {
        value: 'USED',
        label: 'Used',
      },
      {
        value: 'UNSPECIFIED',
        label: 'Unspecified',
      },
    ];

    this.leadTypeOptions = [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: 'MY_DEALERSHIP_VIPS',
        label: 'My dealership VIPS',
      },
      {
        value: 'HENDRICK_VIPS',
        label: 'Hendrick VIPS',
      },
      {
        value: 'PROSPECT',
        label: 'Prospect',
      },
    ];

    this.state = {
      dateLabel: this.props.match.params.dateLabel,
      vehicleType: this.urlVehicleType || this.vehicleTypeOptions[0].value,
      leadType: this.urlLeadType || this.leadTypeOptions[0].value,
      rankingData: [],
    };
  }

  componentDidMount() {
    this.getRanking();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.dateLabel !== state.dateLabel) {
      return {
        dateLabel: props.match.params.dateLabel,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dateLabel === this.state.dateLabel &&
      prevState.vehicleType === this.state.vehicleType &&
      prevState.leadType === this.state.leadType
    ) {
      return;
    }

    this.getRanking();
  }

  getFilterDate = () => {
    const now = new Date();
    let filterDate = now;

    if (this.state.dateLabel === 'week') {
      filterDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
    }
    if (this.state.dateLabel === 'month') {
      filterDate = new Date(now.getFullYear(), now.getMonth(), 1);
    }

    return filterDate.toISOString().slice(0, 10);
  };

  getRanking = () => {
    const filters = [
      { key: 'vehicleType', label: this.state.vehicleType },
      { key: 'leadType', label: this.state.leadType },
    ];
    const searchObj = Object.assign({}, ...filters.map((x) => ({ [x.key]: x.label })));

    let searchParams = new URLSearchParams(searchObj).toString();
    searchParams = searchParams ? `?${searchParams}` : '';

    this.props.history.push(`/ranking/${this.state.dateLabel}${searchParams}`);
    this.api.request
      .get(`ranking/${this.getFilterDate()}${searchParams}`)
      .success((res) => this.setState({ rankingData: res.body }))
      .go();
  };

  onGoToLead = (leadId) => {
    this.props.history.push(`/lead/${leadId}`);
  };

  onInputChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
    this.setState({ rankingData: '' });
  };

  render() {
    const { rankingData, dateLabel, vehicleType, leadType } = this.state;
    const dataIsReady = !!(rankingData && rankingData.length);
    const dateTitle = dateLabel === 'week' ? 'last 7 days' : dateLabel;

    return (
      <PageLayout>
        <Grid container direction="row" justify="space-between">
          <PageTitle title={`Leads of the ${dateTitle}`}>
            {dateLabel === 'day' && <DailyIcon />}
            {dateLabel === 'week' && <WeeklyIcon />}
            {dateLabel === 'month' && <MonthlyIcon />}
          </PageTitle>
          <div>
            <SearchSelectField
              label="Select vehicle type"
              name="vehicleType"
              onChange={this.onInputChange}
              value={vehicleType}
              options={this.vehicleTypeOptions}
            />
            <SearchSelectField
              label="Select lead type"
              name="leadType"
              onChange={this.onInputChange}
              value={leadType}
              options={this.leadTypeOptions}
            />
          </div>
        </Grid>

        {dataIsReady ? (
          <RankingViewTemplate onLeadSelection={this.onGoToLead} data={rankingData} />
        ) : (
          <Paper>
            <EmptyDataPlaceholder>No data to display for this time period</EmptyDataPlaceholder>
          </Paper>
        )}
      </PageLayout>
    );
  }
}
export const RankingByDatePage = withSnackbar(RankingByDatePageComponent);
