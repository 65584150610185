import React from 'react';
import { ServiceLaneBadgeGreyed, ServiceLaneBadgeOther, ServiceLaneBadgeOwn } from '../atoms';
import { ChipBase } from './ChipBase';

const pickBadge = (serviceLaneCount, ownership) => {
  if (serviceLaneCount > 0 && ownership === 'MINE') return <ServiceLaneBadgeOwn />;
  if (serviceLaneCount > 0 && ownership === 'OTHER') return <ServiceLaneBadgeOther />;

  return <ServiceLaneBadgeGreyed />;
};

const ServiceLaneChip = (props) => {
  const component = pickBadge(props.count, props.ownership);
  return ChipBase(props, component);
};

export { ServiceLaneChip };
