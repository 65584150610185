import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Typography,
  withTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GlobalState } from '../../GlobalState';
import { displayDateFormatter } from '../../lib/operators/displayDateFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';
import { TextCellRenderer } from '../atoms';

const styles = (theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 'auto 8px auto 0',
  },
  titleContent: { display: 'inline-flex' },
  chip: {
    margin: '-1px 8px auto 0',
    color: theme.palette.primary.contrastText,
  },
});

class EleadsFeedGridOrganismComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.setupStatics();
  }

  componentDidMount() {
    setTimeout(() => {
      this.dataGrid.api.sizeColumnsToFit();
    }, 2);
  }

  setupStatics = () => {
    this.frameworkComponents = {
      textRenderer: TextCellRenderer,
    };

    const column = (headerName, field) => ({ headerName, field, filter: true, sortable: true });

    this.columnDefinitions = [
      {
        ...column('Date', 'FeedDate'),
        valueFormatter: ({ value }) => displayDateFormatter(value),
        width: 150,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        comparator: gridDatetimeSortComparator,
        cellStyle: (params) => {
          if (params.data.DealershipOwnership === 'MINE') {
            return { color: this.props.theme.hendrick.dataDealershipIndicators.ownDealershipColor };
          }
          return { color: this.props.theme.hendrick.dataDealershipIndicators.otherDealershipColor };
        },
        headerTooltip: 'Date',
        resizable: true,
      },
      {
        ...column('Provider', 'ProviderName'),
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.ProviderName);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Provider',
        resizable: true,
      },
      {
        ...column('Type', 'ProviderType'),
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.ProviderType);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Type',
        resizable: true,
      },
      {
        ...column('Seeking', 'VehicleInterest'),
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.VehicleInterest);
        },
        width: 150,
        cellRenderer: 'textRenderer',
        headerTooltip: 'Seeking',
        resizable: true,
      },
      {
        ...column('Vehicle', 'VehicleJoinedName'),
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.VehicleJoinedName);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Vehicle',
        resizable: true,
      },
      {
        ...column('VIN', 'VehicleVIN'),
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.VehicleVIN);
        },
        cellRenderer: 'textRenderer',
        width: 200,
        headerTooltip: 'VIN',
        resizable: true,
      },
      {
        ...column('Status', 'VehicleStatus'),
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.VehicleStatus);
        },
        cellRenderer: 'textRenderer',
        width: 120,
        headerTooltip: 'Status',
        resizable: true,
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
    };
  };

  render() {
    const { classes, data } = this.props;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="eleads-feed-content"
          id="eleads-feed-header"
        >
          <div className={classes.titleContent}>
            <Typography variant="h6" className={classes.heading}>
              eLeads Feed
            </Typography>
            <Chip label={data.length} color="primary" size="small" variant="outlined" className={classes.chip} />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.paper}>
            <div style={{ height: 250, width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              <AgGridReact
                ref={(c) => (this.dataGrid = c)}
                paginationAutoPageSize
                rowData={data}
                columnDefs={this.columnDefinitions}
                defaultColDef={this.defaultColDef}
                content={{ componentParent: this }}
                frameworkComponents={this.frameworkComponents}
                pagination
                rowHeight={25}
                onFirstDataRendered={this.onFirstDataRendered}
              />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export const EleadsFeedGridOrganism = withStyles(styles)(withTheme(EleadsFeedGridOrganismComponent));
