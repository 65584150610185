import * as React from 'react';
import { withTheme } from '@material-ui/core';

function RedemptionsBadgeGreyedComponent(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 73.598 72.169" {...props}>
      <defs>
        <filter id="gradient_redemptions_greyed" x={0} y={0} width="100%" height="100%" filterUnits="userSpaceOnUse">
          <feOffset />
          <feGaussianBlur stdDeviation={1} result="b" />
          <feFlood floodColor={props.theme.hendrick.greyedDealershipBadgeColor.shadow} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#gradient_redemptions_greyed)">
        <path
          d="M69.31 13.414A16.827 16.827 0 0145.459 34.53L40.8 39.616l3.324 3.331 1.976-1.979a2.589 2.589 0 013.676 0l16.1 16.257a2.6 2.6 0 010 3.676l-7.352 7.346a2.6 2.6 0 01-3.676 0L38.755 51.983a2.6 2.6 0 010-3.676l1.807-1.807-3.162-3.17-22.261 24.317a5.191 5.191 0 01-7.352 0l-1.835-1.835a5.2 5.2 0 010-7.346l25.361-21.243L14.47 20.338l-5.318-.008L3 10.43l4.952-4.966 10.125 6.2.069 5.207 17.037 17.11 4.959-4.151A16.848 16.848 0 0160.1 4.254L49.184 15.021l9.18 9.18zM12.049 61.046a2.6 2.6 0 100 3.676 2.6 2.6 0 000-3.676z"
          fill={props.theme.hendrick.greyedDealershipBadgeColor.base}
        />
      </g>
    </svg>
  );
}

export const RedemptionsBadgeGreyed = withTheme(RedemptionsBadgeGreyedComponent);
