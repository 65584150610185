import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Typography,
  withTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GlobalState } from '../../GlobalState';
import { displayDateFormatter } from '../../lib/operators/displayDateFormatter';
import { displayCurrencyFormatter } from '../../lib/operators/displayCurrencyFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { toGridNumber } from '../../lib/operators/toGridNumber';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';
import { TextCellRenderer } from '../atoms';

const styles = (theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 'auto 8px auto 0',
  },
  titleContent: { display: 'inline-flex' },
  chip: {
    margin: '-1px 8px auto 0',
    color: theme.palette.primary.contrastText,
  },
});

class VehicleSalesGridOrganismComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.setupStatics();
  }

  componentDidMount() {
    setTimeout(() => {
      this.dataGrid.api.sizeColumnsToFit();
    }, 50);
  }

  static getDerivedStateFromProps(props) {
    const columnNames = [
      'DealDate',
      'VehicleSummary',
      'VehiclePriceAsNumber',
      'VehicleGrossProfitAsNumber',
      'FinancedAmountAsNumber',
      'LoanTermination',
      'SalespersonFullname',
      'Dealership',
    ];

    props.data.forEach((r) => {
      r.VehiclePriceAsNumber = toGridNumber(r.VehiclePrice);
      r.VehicleGrossProfitAsNumber = toGridNumber(r.VehicleGrossProfit);
      r.FinancedAmountAsNumber = toGridNumber(r.FinancedAmount);
    });

    const buildDeduperString = (model) => {
      return columnNames.map((name) => model[name]).join('#-#');
    };

    const deduperKeys = Array.from(new Set(props.data.map(buildDeduperString)));
    const dedupedData = deduperKeys.map((key) => props.data.find((m) => buildDeduperString(m) === key));

    return { dedupedData };
  }

  setupStatics = () => {
    this.frameworkComponents = {
      textRenderer: TextCellRenderer,
    };

    this.columnDefinitions = [
      {
        headerName: 'Date',
        field: 'DealDate',
        valueFormatter: ({ value }) => displayDateFormatter(value),
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: gridDatetimeFilterComparator,
        },
        comparator: gridDatetimeSortComparator,
        width: 70,
        cellStyle: (params) => {
          if (params.data.DealershipOwnership === 'MINE') {
            return { color: this.props.theme.hendrick.dataDealershipIndicators.ownDealershipColor };
          }
          return { color: this.props.theme.hendrick.dataDealershipIndicators.otherDealershipColor };
        },
        headerTooltip: 'Date',
        resizable: true,
      },
      {
        headerName: 'Vehicle',
        field: 'VehicleSummary',
        width: 100,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.VehicleSummary);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Vehicle',
        resizable: true,
      },
      {
        headerName: 'Sales price',
        field: 'VehiclePriceAsNumber',
        width: 75,
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ value }) => displayCurrencyFormatter(value),
        cellRenderer: 'textRenderer',
        headerTooltip: 'Sales price',
        resizable: true,
      },
      {
        headerName: 'Gross Profit',
        field: 'VehicleGrossProfitAsNumber',
        width: 75,
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ value }) => displayCurrencyFormatter(value),
        cellRenderer: 'textRenderer',
        headerTooltip: 'Gross Profit',
        resizable: true,
      },
      {
        headerName: 'Financed',
        field: 'FinancedAmountAsNumber',
        width: 80,
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ value }) => displayCurrencyFormatter(value),
        cellRenderer: 'textRenderer',
        headerTooltip: 'Financed',
        resizable: true,
      },
      {
        headerName: 'Loan End Date',
        field: 'LoanTermination',
        width: 90,
        valueFormatter: ({ value }) => displayDateFormatter(value),
        headerTooltip: 'Loan End Date',
        resizable: true,
      },
      {
        headerName: 'Sold by',
        field: 'SalespersonFullname',
        width: 90,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.SalespersonFullname);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Sold by',
        resizable: true,
      },
      {
        headerName: 'Dealership',
        field: 'DealershipName',
        width: 80,
        valueGetter: (params) => {
          return completeEmptyOrNullFields(params.data.DealershipName);
        },
        cellRenderer: 'textRenderer',
        headerTooltip: 'Dealership',
        resizable: true,
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
    };

    this.onFirstDataRendered = (params) => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 50);
    };
  };

  render() {
    const { classes, expanded, onChange } = this.props;
    const { dedupedData } = this.state;

    return (
      <ExpansionPanel expanded={expanded} onChange={onChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="vehicle-sales-summary-content"
          id="vehicle-sales-summary-header"
        >
          <div className={classes.titleContent}>
            <Typography variant="h6" className={classes.heading}>
              Vehicle Sales Summary
            </Typography>
            <Chip label={dedupedData.length} color="primary" size="small" variant="outlined" className={classes.chip} />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.paper}>
            <div style={{ height: 250, width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              <AgGridReact
                ref={(c) => (this.dataGrid = c)}
                paginationAutoPageSize
                rowData={dedupedData}
                columnDefs={this.columnDefinitions}
                defaultColDef={this.defaultColDef}
                content={{ componentParent: this }}
                frameworkComponents={this.frameworkComponents}
                pagination
                rowHeight={25}
                onFirstDataRendered={this.onFirstDataRendered}
              />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export const VehicleSalesGridOrganism = withStyles(styles)(withTheme(VehicleSalesGridOrganismComponent));
