import React from 'react';
import { ProtectionProductBadgeGreyed, ProtectionProductBadgeOther, ProtectionProductBadgeOwn } from '../atoms';
import { ChipBase } from './ChipBase';

const pickBadge = (productCount, ownership) => {
  if (productCount > 0 && ownership === 'MINE') return <ProtectionProductBadgeOwn />;
  if (productCount > 0 && ownership === 'OTHER') return <ProtectionProductBadgeOther />;

  return <ProtectionProductBadgeGreyed />;
};

const ProtectionProductChip = (props) => {
  const component = pickBadge(props.count, props.ownership);
  return ChipBase(props, component);
};

export { ProtectionProductChip };
