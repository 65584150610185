import React, { PureComponent } from 'react';
import { withSnackbar } from 'notistack';
import { PageLayout, LeadViewTemplate } from '../template';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { API } from '../../lib/xhr';

class LeadViewPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    const leadId = this.props.match.params.id;

    this.notifier = new SnackbarVisitor(props);
    this.api = new API(this.notifier);

    this.state = {
      leadId,
      lead: null,
      sales: [],
      serviceLane: [],
      eleadsFeed: [],
      protectionProducts: [],
      unredeemedProducts: [],
      salesProfiling: null,
      eleadFeedProfiling: null,
      intenderProfiling: null,
      intenderRecords: [],
      unredeemedProductsProfiling: null,
      householdAndGarage: null,
      badges: null,
      serviceLaneProfiling: null,
      protectionProductsProfiling: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.id !== state.leadId) {
      return {
        leadId: props.match.params.id,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.leadId === this.state.leadId) return;

    this.refreshAll();
  }

  componentDidMount() {
    this.refreshAll();
  }

  refreshAll = () => {
    this.refreshLead();
    this.refreshSales();
    this.refreshSalesProfiling();
    this.refreshServiceLane();
    this.refreshEleadsFeed();
    this.refreshEleadFeedProfiling();
    this.refreshBigdbmProfiling();
    this.refreshIntenderRecords();
    this.refreshHouseholdAndGarage();
    this.refreshBadges();
    this.refreshServiceLaneProfiling();
    this.refreshProtectionProducts();
    this.refreshProtectionProductsProfiling();
    this.refreshUnredeemedProducts();
    this.refreshUnredeemedProductsProfiling();
  };

  refreshLead = () => {
    this.api.request
      .get(`lead/${this.state.leadId}`)
      .success((res) => this.setState({ lead: res.body }))
      .go();
  };

  refreshSales = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/sales`)
      .success((res) => this.setState({ sales: res.body }))
      .go();
  };

  refreshServiceLane = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/serviceLane`)
      .success((res) => this.setState({ serviceLane: res.body }))
      .go();
  };

  refreshProtectionProducts = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/protectionProducts`)
      .success((res) => this.setState({ protectionProducts: res.body }))
      .go();
  };

  refreshEleadsFeed = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/eleadsFeed`)
      .success((res) => this.setState({ eleadsFeed: res.body }))
      .go();
  };

  refreshSalesProfiling = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/sales/profiling`)
      .success((res) => this.setState({ salesProfiling: res.body }))
      .go();
  };

  refreshEleadFeedProfiling = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/eleadsFeed/profiling`)
      .success((res) => this.setState({ eleadFeedProfiling: res.body }))
      .go();
  };

  refreshServiceLaneProfiling = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/serviceLane/profiling`)
      .success((res) => this.setState({ serviceLaneProfiling: res.body }))
      .go();
  };

  refreshBigdbmProfiling = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/intender/profiling`)
      .success((res) => this.setState({ intenderProfiling: res.body }))
      .go();
  };

  refreshIntenderRecords = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/intender`)
      .success((res) => this.setState({ intenderRecords: res.body }))
      .go();
  };

  refreshHouseholdAndGarage = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/intenderInternal`)
      .success((res) => this.setState({ householdAndGarage: res.body }))
      .go();
  };

  refreshBadges = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/badges`)
      .success((res) => this.setState({ badges: res.body }))
      .go();
  };

  refreshProtectionProductsProfiling = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/protectionProducts/profiling`)
      .success((res) => this.setState({ protectionProductsProfiling: res.body }))
      .go();
  };

  refreshUnredeemedProducts = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/unredeemedProducts`)
      .success((res) => this.setState({ unredeemedProducts: res.body }))
      .go();
  };

  refreshUnredeemedProductsProfiling = () => {
    this.api.request
      .get(`lead/${this.state.leadId}/unredeemedProducts/profiling`)
      .success((res) => this.setState({ unredeemedProductsProfiling: res.body }))
      .go();
  };

  render() {
    const {
      lead,
      sales,
      intenderRecords,
      salesProfiling,
      eleadFeedProfiling,
      intenderProfiling,
      serviceLane,
      eleadsFeed,
      householdAndGarage,
      badges,
      serviceLaneProfiling,
      protectionProducts,
      protectionProductsProfiling,
      unredeemedProducts,
      unredeemedProductsProfiling,
    } = this.state;
    const ready = [
      lead,
      salesProfiling,
      eleadFeedProfiling,
      intenderProfiling,
      householdAndGarage,
      badges,
      serviceLaneProfiling,
      protectionProductsProfiling,
      unredeemedProductsProfiling,
    ].every(Boolean);

    return (
      <PageLayout>
        {ready && (
          <LeadViewTemplate
            lead={lead}
            sales={sales}
            intenderRecords={intenderRecords}
            serviceLane={serviceLane}
            eleadsFeed={eleadsFeed}
            protectionProducts={protectionProducts}
            unredeemedProducts={unredeemedProducts}
            salesProfiling={salesProfiling}
            eleadFeedProfiling={eleadFeedProfiling}
            intenderProfiling={intenderProfiling}
            unredeemedProductsProfiling={unredeemedProductsProfiling}
            householdAndGarage={householdAndGarage}
            badges={badges}
            serviceLaneProfiling={serviceLaneProfiling}
            protectionProductsProfiling={protectionProductsProfiling}
          />
        )}
      </PageLayout>
    );
  }
}

export const LeadViewPage = withSnackbar(LeadViewPageComponent);
