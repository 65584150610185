import { LocalStorage } from '../storage/LocalStorage';

class PermissionsMediatorComponent {
  storage = new LocalStorage();

  _dataCache = [];

  _permissionsKey = 'hendrickDynamicsPermissions';

  _dealershipsKey = 'dealerships';

  _asaKey = 'asaPermissions';

  constructor() {
    this.tryRefreshFromLocalStorage();
  }

  clearData() {
    this.storage.clear();
  }

  tryRefreshFromLocalStorage() {
    const permissions = JSON.parse(this.storage.get(this._permissionsKey));
    const dealerships = this.storage.get(this._dealershipsKey);
    const asaPermissions = this.storage.get(this._asaKey);

    this.refreshData(permissions, dealerships, asaPermissions);
  }

  isLoggedIn() {
    return !!this.storage.get(this._permissionsKey);
  }

  // Call this from security views where security actions occur
  // to refresh the current permissions cache you have
  refreshData(permissions, dealerships, asaPermissions) {
    this.clearData();

    this._dataCache = permissions;

    const jsonAuthorization = JSON.stringify(permissions);
    if (permissions !== null) this.storage.set(this._permissionsKey, jsonAuthorization);
    if (dealerships !== null) this.storage.set(this._dealershipsKey, dealerships);
    if (asaPermissions !== null) this.storage.set(this._asaKey, asaPermissions);
  }

  // Send your permissions as a list of string arguments.
  // Usually you will only need one permission by each functionality.
  has(permissionCode) {
    return !!this._dataCache[permissionCode];
  }

  hasAll(...permissions) {
    let hasAllPermissions = true;

    for (let i = 0; i < permissions.length; i++) {
      const code = permissions[i];

      hasAllPermissions = hasAllPermissions && !!this._dataCache[code];

      if (!hasAllPermissions) break;
    }

    return hasAllPermissions;
  }

  hasOne(...permissions) {
    let hasOne = false || permissions.length === 0;
    for (let i = 0; i < permissions.length; i++) {
      const code = permissions[i];

      hasOne = !!this._dataCache[code];
      if (hasOne) break;
    }

    return hasOne;
  }
}

// Export as singleton
export const PermissionsMediator = new PermissionsMediatorComponent();
