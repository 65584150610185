/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-globals */

export function gridTextToNumberComparator(filter, value, filterText) {
  const filterTextLoweCase = filterText.toLowerCase();
  const valueLowerCase = value.toString().toLowerCase();

  switch (filter) {
    case 'contains':
      const filterValuesToInclude = filterText.split(',');
      return filterValuesToInclude.includes(value);

    case 'notContains':
      const filterValuesToExclude = filterText.split(',');
      return !filterValuesToExclude.includes(value);

    case 'equals':
      return valueLowerCase === filterTextLoweCase;

    case 'notEqual':
      return valueLowerCase !== filterTextLoweCase;

    case 'startsWith':
      return valueLowerCase.indexOf(filterTextLoweCase) === 0;

    case 'endsWith':
      const index = valueLowerCase.lastIndexOf(filterTextLoweCase);
      return index >= 0 && index === valueLowerCase.length - filterTextLoweCase.length;

    default:
      // should never happen
      return false;
  }
}
