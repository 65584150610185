import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#f7c4be',
    color: theme.palette.primary.main,
    padding: '5px 40px 5px 40px',
    borderRadius: '4px',
    margin: 'auto',
  },
  message: {
    marginLeft: theme.spacing(1),
  },
}));

const AlertBox = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Box className={classes.content}>
      {children}
      <Typography variant="caption" className={classes.message}>
        {props.message}
      </Typography>
    </Box>
  );
};

export { AlertBox };
