import React, { PureComponent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Paper, Typography, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Radio from '@material-ui/core/Radio';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { API } from '../../lib/xhr';
import { SnackbarVisitor } from '../../lib/snackbar/SnackbarVisitor';
import { PageTitle, EmptyDataPlaceholder } from '../molecules';
import {
  PageLayout,
  LeadSearchTemplate,
  LeadSearchGridTemplate,
  VehicleSearchFormTemplate,
  VehicleShopperGridTemplate,
  VehicleOwnerGridTemplate,
} from '../template';

class LeadSearchPageComponent extends PureComponent {
  constructor(props) {
    super(props);

    const urlSearchParams = new URLSearchParams(this.props.location.search);

    this.notifier = new SnackbarVisitor(props);
    this.api = new API(this.notifier);

    const thisYear = new Date().getFullYear();
    const maxOffseFromThisYear = 60;

    this.yearRangeOptions = [];

    for (let i = 0; i <= maxOffseFromThisYear; i++) {
      this.yearRangeOptions.push({ value: thisYear - i, label: thisYear - i });
    }

    const typeSelectedSearch = this.props.location.pathname.split('/');

    this.vehicleYearRangeOptions = [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'from_to',
        label: 'From-To',
      },
      {
        value: 'from',
        label: 'From',
      },
      {
        value: 'to',
        label: 'To',
      },
    ];

    const initialState = {
      firstname: '',
      lastname: '',
      address: '',
      email: '',
      phone: '',
      eleadId: '',
      asa: '',
      limit: 100,
      chips: [],
      filteredData: [],
      searchDidExecute: false,
      selectedSearch: typeSelectedSearch[typeSelectedSearch.length - 1],
      displayExpirationDialog: false,
      vehicleMake: '',
      vehicleModel: '',
      vin: '',
      state: '',
      stock: '',
      vehicleYearRangeSelection: this.vehicleYearRangeOptions[0].value,
      vehicleYearRangeFrom: thisYear,
      vehicleYearRangeTo: thisYear,
      typeSearch: this.props.match.params.typeSearch,
    };
    this.initialState = initialState;
    const urlParams = [];
    urlSearchParams.forEach((value, key) => urlParams.push({ [key]: value }));

    this.state = Object.assign({}, initialState, ...urlParams);

    this.shouldSearchOnLoad = false;
    this.maxLimitValue = 2000;
    this.minLimitValue = 1;
  }

  initialState = {};

  componentDidMount() {
    this.setFilters(this.shouldSearchOnLoad);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.typeSearch !== state.typeSearch) {
      return {
        typeSearch: props.match.params.typeSearch,
      };
    }
    return null;
  }

  onInputChange = (evt) => this.setState({ [evt.target.name]: evt.target.value });

  onSearchCriteriaDelete = (key) => {
    this.setState((prevState) => ({ [key]: '', chips: prevState.chips.filter((x) => x.key !== key) }));
  };

  setFilters = (shouldSearchExecute) => {
    const fieldsToCheck =
      this.state.selectedSearch === 'lead'
        ? ['firstname', 'lastname', 'address', 'email', 'phone', 'eleadId', 'asa']
        : ['vehicleMake', 'vehicleModel', 'vin', 'state', 'stock'];
    const chips = [];
    fieldsToCheck.forEach((field) => {
      const value = this.state[field];
      if (value) {
        chips.push({ key: field, label: value });
      }
    });

    this.setState({ chips }, () => {
      const searchObj = Object.assign({}, ...chips.map((x) => ({ [x.key]: x.label })));
      searchObj.limit = this.state.limit.toString();

      let searchParams = new URLSearchParams(searchObj).toString();
      searchParams = searchParams ? `?${searchParams}` : '';

      this.props.history.push(`/search/${this.state.selectedSearch}${searchParams}`);
      if (shouldSearchExecute && this.areFiltersValid(chips) && this.IsLimitValid()) this.search(searchParams);
    });
  };

  onSearch = () => {
    const executeSearch = true;
    this.setState({ searchDidExecute: true });
    this.setFilters(executeSearch);
  };

  search = (searchParams) => {
    let url;
    switch (this.state.selectedSearch) {
      case 'lead':
        url = `search`;
        break;
      case 'shoppers':
        url = `vehiclesearch/seerker`;
        break;
      case 'owners':
        url = `vehiclesearch/owner`;
        break;
      default:
        url = ``;
    }

    this.api.request
      .get(`${url}${searchParams}`)
      .success((res) => this.setState({ filteredData: res.body }))
      .go();
  };

  onGoToLead = (leadId) => {
    this.props.history.push(`/${this.state.selectedSearch}/${leadId}`);
  };

  areFiltersValid(chips) {
    if (chips.length <= 0) {
      this.notifier.warning('Please enter at least one search criteria');
      return false;
    }
    return true;
  }

  IsLimitValid() {
    if (this.state.limit > this.maxLimitValue || this.state.limit < this.minLimitValue) {
      this.notifier.error('The search limit must be between 1 and 2000.');
      return false;
    }
    return true;
  }

  onHandleChange = (evt) => {
    this.props.history.push(`/search/${evt.target.value}?limit=100`);
    this.setState(this.initialState);
    this.setState({ selectedSearch: evt.target.value });
  };

  render() {
    const {
      firstname,
      lastname,
      phone,
      address,
      email,
      eleadId,
      asa,
      limit,
      chips,
      filteredData,
      searchDidExecute,
      selectedSearch,
      vehicleMake,
      vehicleModel,
      vin,
      state,
      stock,
      vehicleYearRangeSelection,
      vehicleYearRangeFrom,
      vehicleYearRangeTo,
    } = this.state;
    const dataIsReady = !!(filteredData && filteredData.length);

    return (
      <PageLayout>
        <PageTitle title="Find a Lead">
          <SearchIcon />
        </PageTitle>
        <ExpansionPanel expanded={false}>
          <ExpansionPanelSummary aria-controls="panel1c-content" id="panel1c-header">
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="overline">Search type</Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={selectedSearch}
                    value={selectedSearch}
                    onChange={this.onHandleChange}
                  >
                    <FormControlLabel value="lead" control={<Radio color="third" />} label="Lead" />
                    <FormControlLabel value="shoppers" control={<Radio color="third" />} label="Vehicle Shoppers" />
                    <FormControlLabel value="owners" control={<Radio color="third" />} label="Vehicle Owners" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        {selectedSearch === 'lead' && (
          <LeadSearchTemplate
            firstname={firstname}
            lastname={lastname}
            phone={phone}
            address={address}
            email={email}
            eleadId={eleadId}
            asa={asa}
            chips={chips}
            onSearchCriteriaDelete={this.onSearchCriteriaDelete}
            onInputChange={this.onInputChange}
            onSearch={this.onSearch}
            limit={limit}
          >
            {dataIsReady && selectedSearch === 'lead' && (
              <LeadSearchGridTemplate onLeadSelection={this.onGoToLead} data={filteredData} />
            )}
            {!dataIsReady && searchDidExecute && (
              <Paper>
                <EmptyDataPlaceholder>No data found matching this search criteria</EmptyDataPlaceholder>
              </Paper>
            )}
          </LeadSearchTemplate>
        )}
        {selectedSearch !== 'lead' && (
          <VehicleSearchFormTemplate
            vehicleMake={vehicleMake}
            vehicleModel={vehicleModel}
            vehicleYearRangeSelection={vehicleYearRangeSelection}
            vehicleYearRangeOptions={this.vehicleYearRangeOptions}
            yearRangeOptions={this.yearRangeOptions}
            vehicleYearRangeFrom={vehicleYearRangeFrom}
            vehicleYearRangeTo={vehicleYearRangeTo}
            vin={vin}
            state={state}
            stock={stock}
            chips={chips}
            selectedSearch={selectedSearch}
            onSearchCriteriaDelete={this.onSearchCriteriaDelete}
            onInputChange={this.onInputChange}
            onSearch={this.onSearch}
            limit={limit}
          >
            {dataIsReady && selectedSearch === 'shoppers' && (
              <VehicleShopperGridTemplate onLeadSelection={this.onGoToLead} data={filteredData} />
            )}
            {dataIsReady && selectedSearch === 'owners' && (
              <VehicleOwnerGridTemplate onLeadSelection={this.onGoToLead} data={filteredData} />
            )}
            {!dataIsReady && searchDidExecute && (
              <Paper>
                <EmptyDataPlaceholder>No data found matching this search criteria</EmptyDataPlaceholder>
              </Paper>
            )}
          </VehicleSearchFormTemplate>
        )}
      </PageLayout>
    );
  }
}

export const LeadSearchPage = withSnackbar(LeadSearchPageComponent);
