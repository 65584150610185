import * as React from 'react';
import { withTheme } from '@material-ui/core';

function WeeklyIconComponent(props) {
  return (
    <svg width={25} viewBox="0 0 152.43 139.54" {...props}>
      <g fill="none">
        <g data-name="Path 140">
          <path d="M20.488 26.387H135a9.464 9.368 0 019.464 9.368v84.313a9.464 9.368 0 01-9.464 9.368H20.488a9.464 9.368 0 01-9.464-9.368V35.755a9.464 9.368 0 019.464-9.368z" />
          <path d="M20.488 30.72c-2.805 0-5.087 2.26-5.087 5.036v84.315c0 2.776 2.282 5.035 5.087 5.035H135c2.805 0 5.087-2.259 5.087-5.035V35.756c0-2.777-2.282-5.036-5.087-5.036H20.488m0-4.333H135c5.227 0 9.464 4.195 9.464 9.368v84.315c0 5.174-4.237 9.368-9.464 9.368H20.488c-5.227 0-9.464-4.194-9.464-9.368V35.755c0-5.174 4.237-9.368 9.464-9.368z" />
        </g>
        <g stroke={props.theme.palette.primary.contrastIcon} transform="matrix(1.0553 0 0 1.0446 -3364.67 1061.356)">
          <path d="M3199.3-962.79h122.87" strokeWidth={9.146} data-name="Path 141" />
          <path d="M3225.9-1008.7v25.742" strokeLinecap="round" strokeWidth={13.832} data-name="Path 138" />
          <path d="M3292.3-1008.7v25.742" strokeLinecap="round" strokeWidth={13.832} data-name="Path 139" />
          <rect x={3195.9} y={-991.47} width={128.46} height={105.95} ry={10.551} strokeWidth={9.845} />
        </g>
      </g>
      <g
        transform="matrix(1.0108 0 0 .9917 -3693.83 804.11)"
        fill={props.theme.palette.primary.contrastIcon}
        stroke={props.theme.palette.primary.contrastIcon}
        strokeWidth={1.102}
        data-name="Group 160"
      >
        <rect x={3730.9} y={-731.3} width={21.105} height={20.771} rx={5.554} data-name="Rectangle 57" />
        <rect x={3705.4} y={-731.3} width={21.105} height={20.771} rx={5.554} data-name="Rectangle 58" />
        <rect x={3756.5} y={-731.3} width={21.105} height={20.771} rx={5.554} data-name="Rectangle 59" />
        <rect x={3680} y={-706.53} width={21.105} height={20.771} rx={5.554} data-name="Rectangle 61" />
        <rect x={3705.6} y={-706.53} width={21.105} height={20.771} rx={5.554} data-name="Rectangle 62" />
      </g>
    </svg>
  );
}

export const WeeklyIcon = withTheme(WeeklyIconComponent);
