import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Divider,
  Avatar,
} from '@material-ui/core';
import { Check as TickIcon } from '@material-ui/icons';
import { EmptyDataPlaceholder } from './EmptyDataPlaceholder';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.light,
    height: '5rem',
  },
  householdAvatar: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.secondary.main,
  },
  summaryAvatar: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
  },
  emptyCard: {
    textAlign: 'center',
    padding: '40px 0 40px 0',
  },
}));

// const leadPersonProfile = [
//   'Multi-time new vehicle buyer',
//   'Owns 2018 Lexus IS 350',
//   'Visited Dealer Dec 12th',
//   'In-market Luxury Car',
//   'Flexible maintenance policy',
//   '$5K lifetime service spend',
//   '3 outbound calls',
//   '4 positive reputation reviews',
// ];

// const leadHouseholdProfile = [
//   'Owns 4 vehicles',
//   'Last visit to dealer May 22nd',
//   '$17K lifetime service spend',
//   '43 websites visited in the last 30 days',
//   '1 purchase done online',
// ];

const generateDatapoints = (items) => {
  const output = items.map((item) => (
    <ListItem>
      <ListItemIcon>
        <TickIcon />
      </ListItemIcon>
      <ListItemText primary={item.Text} />
    </ListItem>
  ));

  return output;
};

export const ProfilingCard = (props) => {
  const { items, title, children } = props;
  const classes = useStyles();
  return (
    <Card>
      <CardHeader
        title={title}
        className={classes.header}
        titleTypographyProps={{ variant: 'h6' }}
        avatar={<Avatar className={classes.summaryAvatar}>{children}</Avatar>}
      />
      <Divider />
      <CardContent>
        {items.length ? (
          <List dense disablePadding>
            {generateDatapoints(items)}
          </List>
        ) : (
          <EmptyDataPlaceholder>No data to display</EmptyDataPlaceholder>
        )}
      </CardContent>
    </Card>
  );
};
