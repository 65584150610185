/* eslint-disable no-restricted-globals */

export const displayCurrencyFormatter = (value, decimalDigits = 2) => {
  if (!value) return '-';

  if (value.trim && !value.trim().length) return '-';
  if (value === 0) return '-';

  let formattableValue = value;

  if (!value.toFixed) {
    formattableValue = parseFloat(value);

    // Return as is
    if (isNaN(formattableValue)) return value;
  }

  return `$ ${formattableValue.toFixed(decimalDigits)}`;
};
