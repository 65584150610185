import React from 'react';
import Button from '@material-ui/core/Button';
import { ModalDialog } from './ModalDialog';
import { GlobalState } from '../../GlobalState';

const SessionDialog = (props) => {
  return (
    <ModalDialog
      open={props.isOpen}
      title="Your session is about to expire"
      description="your session will expire in 5 minutes. Choose 'Stay' to continue signed in"
    >
      <Button onClick={GlobalState.AppComponent.performSignOut} color="secondary">
        Sign out
      </Button>
      <Button onClick={GlobalState.AppComponent.handleSessionRefresh} color="secondary" autoFocus>
        Stay
      </Button>
    </ModalDialog>
  );
};

export { SessionDialog };
