import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import DeskbuddyFont from '../fonts/ShadowsIntoLightTwo-Regular.ttf';
import BebasNeueFont from '../fonts/BebasNeue-Regular.ttf';
import LatoFont from '../fonts/Lato-Regular.ttf';

const deskbuddyFont = {
  fontFamily: 'DeskbuddyFont',
  src: `url(${DeskbuddyFont})`,
};
const bebasNeueFont = {
  fontFamily: 'BebasNeueFont',
  src: `url(${BebasNeueFont})`,
};
const latoFont = {
  fontFamily: 'LatoFont',
  src: `url(${LatoFont})`,
};
const ownDealershipColor = '#0061E2';
const otherDealershipColor = '#00B050';
const currentAsaColor = '#0061E2';

const commonHendrickColors = {
  dataDealershipIndicators: {
    ownDealershipColor,
    otherDealershipColor,
  },
  asaIndicators: {
    currentAsaColor,
  },
  ownDealershipBadgeColor: {
    base: '#144286',
    light: '#44A4F7',
  },
  otherDealershipBadgeColor: {
    base: '#1F9E11',
    light: '#9FF780',
  },
  redDealershipBadgeColor: {
    base: '#DB0000',
    light: '#FF0000',
  },
  yellowDealershipBadgeColor: {
    base: '#ECED00',
    light: '#FFFF00',
  },
  greyedDealershipBadgeColor: {
    base: '#fff',
    shadow: '#292929',
  },
};

const buildLight = () =>
  createMuiTheme({
    hendrick: {
      ...commonHendrickColors,
      inMarketIndicator: '#e21a6d',
    },
    palette: {
      type: 'light',
      primary: {
        main: '#4A4A4A',
        light: '#a8a6a6',
        dark: '#171717',
        contrastText: '#101010',
        contrastIcon: '#4A4A4A',
        white: '#f8f8f8',
        black: '#101010',
      },
      secondary: {
        main: '#ED1C24',
      },
      error: {
        main: red.A400,
      },
      success: {
        main: '#87e069',
      },
      background: {
        default: '#E1E2E1',
        paper: '#fff',
      },
    },
    typography: {
      subtitle1: {
        fontFamily: '"DeskbuddyFont"',
      },
      body1: {
        fontFamily: '"LatoFont"',
      },
      body2: {
        fontFamily: '"LatoFont"',
      },
      bebas: {
        fontFamily: '"BebasNeueFont"',
      },
      h6: {
        fontFamily: '"BebasNeueFont"',
        letterSpacing: '0.08rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [deskbuddyFont, bebasNeueFont, latoFont],
        },
      },
    },
  });

const buildDark = () =>
  createMuiTheme({
    hendrick: {
      ...commonHendrickColors,
      inMarketIndicator: '#E2B91A',
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#252525',
        light: '#505050',
        dark: '#171717',
        contrastText: '#fff',
        contrastIcon: '#D9D9D9',
        white: '#fff',
        black: '#101010',
      },
      secondary: {
        main: '#ED1C24',
      },
      error: {
        main: red.A400,
      },
      success: {
        main: '#87e069',
      },
      background: {
        default: '#252525',
        paper: '#171717',
      },
    },
    typography: {
      subtitle1: {
        fontFamily: '"DeskbuddyFont"',
      },
      body1: {
        fontFamily: '"LatoFont"',
      },
      body2: {
        fontFamily: '"LatoFont"',
      },
      bebas: {
        fontFamily: '"BebasNeueFont"',
      },
      h6: {
        fontFamily: '"BebasNeueFont"',
        letterSpacing: '0.08rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [deskbuddyFont, bebasNeueFont, latoFont],
        },
      },
    },
  });

export { buildLight, buildDark };
