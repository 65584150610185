import * as React from 'react';
import { withTheme } from '@material-ui/core';

function VehicleBadgeRedDealership(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 152.179 43.814" {...props}>
      <defs>
        <radialGradient
          id="gradient_vehicle_red_dealership"
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientTransform="matrix(-1 0 0 1 1 0)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor={props.theme.hendrick.redDealershipBadgeColor.light} />
          <stop offset={1} stopColor={props.theme.hendrick.redDealershipBadgeColor.base} />
        </radialGradient>
      </defs>
      <path
        d="M72.544 112.658c-7.985.006-16.449.371-25.364 2.656-.529.769-.95 1.308-1.543 1.423-8.576 2.17-22.1 4.655-36.528 8.588-5.868.7-6.3-.029-7.383 7.253a20.916 20.916 0 000 3.551 2.055 2.055 0 01-.463 1.028c-1.141 1.354-.956 5.745.589 6.362 1.347.538 20.579 8.686 20.009 3.732 0-7.437 4.806-12.644 11.126-13.353 8.957-1.005 13.989 5.885 13.7 14.36-.062 1.842.251 2.483 1.1 2.811.182.07 15.092.135 33.133.145 36.831.02 33.764.856 34.106-1.445 1.2-7.741 3.637-14.857 13.22-14.914 6.314-.037 11.49 4.1 11.558 11.417.032 3.49.141 3.637 2.681 3.637 3.88 0 6.379.7 7.973-2.988.668-1.192 2.219-3.281 2.245-4.947 0-.606-.1-1.1-.22-1.1s-.221-.137-.221-.3c0-.3-.18-.389-2.314-1.136-1.626-.569-1.653-.61-1.653-2.522 0-2.442-.175-3.423 1.317-3.422 1.732 0 1.989-1.114-.4-2.6a18.642 18.642 0 00-8.742-3.249c-10.709-1.256-21.383-1.922-33.183-2.656a17.3 17.3 0 00-4.763.038c-1.335.276-1.335.276-3.017-.919-3.616-2.567-8-5.538-11.969-8.358-4.452-3.176-4.014-3.086-15-3.086zm.064 3.1c4.213-.041 7.919.419 9.348 1.245a39.962 39.962 0 014.077 2.955q6.287 5.218 6.282 5.723c0 .211-2.556.2-13.665-.035a979.483 979.483 0 00-13.583-.179c-8.284-.037-7.8.056-8.576-1.649-.339-.746-.9-1.961-1.241-2.7-1.043-2.244-.556-3.082 2.13-3.662 4.074-.88 4.007-.87 10.911-1.486a53.93 53.93 0 014.317-.212zm-38.1 19.79a10.311 10.311 0 10.029 0h-.029zm93.57.327a10.311 10.311 0 10.029 0h-.029zM34.452 141.6h.006a4.328 4.328 0 11-.007 0zm93.57.327h.006a4.328 4.328 0 11-.006 0z"
        transform="translate(-.529 -112.658)"
        fill="url(#gradient_vehicle_red_dealership)"
      />
    </svg>
  );
}

export const VehicleBadgeRed = withTheme(VehicleBadgeRedDealership);
