import React, { PureComponent } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { BadgesCellRenderer, NamesCellRenderer, RankingNumberCellRenderer, TextCellRenderer } from '../atoms';
import { GlobalState } from '../../GlobalState';
import { displayDatetimeFormatter } from '../../lib/operators/displayDatetimeFormatter';
import { displayPercentFormatter } from '../../lib/operators/displayPercentFormatter';
import { gridDatetimeFilterComparator } from '../../lib/operators/gridDatetimeFilterComparator';
import { gridDatetimeSortComparator } from '../../lib/operators/gridDatetimeSortComparator';
import { gridTextToNumberComparator } from '../../lib/operators/gridTextToNumberComparator';
import { gridBadgeComparator } from '../../lib/operators/gridBadgeComparator';
import { gridPercentComparator } from '../../lib/operators/gridPercentComparator';
import { LocalStorage } from '../../lib/storage/LocalStorage';
import { completeEmptyOrNullFields } from '../../lib/operators/completeEmptyOrNullFields';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
  },
});

class RankingViewTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.asaPermissions = new LocalStorage().get('asaPermissions');
    this.setupStatics();
  }

  componentDidMount() {
    this.dataGrid.api.sizeColumnsToFit();

    this.dataGrid.api.addEventListener('selectionChanged', this.onRowSelection);
  }

  onRowSelection = (evt) => {
    const selected = evt.api.getSelectedRows();
    const leadId = selected[0].lead_identity_id;
    this.props.onLeadSelection(leadId);
  };

  setupStatics = () => {
    this.frameworkComponents = {
      badgesRenderer: BadgesCellRenderer,
      namesCellRenderer: NamesCellRenderer,
      rankRenderer: RankingNumberCellRenderer,
      textRenderer: TextCellRenderer,
    };

    this.onFirstDataRendered = (params) => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 50);
    };

    this.columnDefinitions = [
      {
        children: [
          {
            headerName: '#',
            field: 'ranking_position',
            cellRenderer: 'rankRenderer',
            sortable: true,
            width: 0,
            headerTooltip: '#',
            resizable: true,
          },
          {
            headerName: 'Lead',
            field: 'full_name',
            cellRenderer: 'textRenderer',
            filter: true,
            sortable: true,
            width: 155,
            headerTooltip: 'Lead',
            resizable: true,
          },
          {
            headerName: 'Match Probability',
            field: 'match_probability',
            filter: true,
            sortable: true,
            width: 92,
            colId: 'matchProbability',
            comparator: gridPercentComparator,
            valueFormatter: ({ value }) => displayPercentFormatter(value),
            cellStyle: (params) => {
              let color;

              switch (true) {
                case params.value >= 80:
                  color = this.props.theme.hendrick.otherDealershipBadgeColor.light;
                  break;
                case params.value >= 60 && params.value < 80:
                  color = this.props.theme.hendrick.yellowDealershipBadgeColor.light;
                  break;
                case params.value < 60:
                  color = this.props.theme.hendrick.greyedDealershipBadgeColor.light;
                  break;
                default:
                  color = this.props.theme.hendrick.greyedDealershipBadgeColor.light;
              }
              return {
                justifyContent: 'left',
                color,
              };
            },
            headerTooltip: 'Match Probability',
            resizable: true,
          },
        ],
      },
      {
        headerName: 'Hendrick Relationships',
        children: [
          {
            headerName: 'Vehicles',
            field: 'sales_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'vehicle',
            sortable: true,
            width: 90,
            comparator: gridBadgeComparator,
            headerTooltip: 'Vehicles',
            resizable: true,
          },
          {
            headerName: 'Services',
            field: 'services_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'serviceLane',
            sortable: true,
            width: 84,
            comparator: gridBadgeComparator,
            headerTooltip: 'Services',
            resizable: true,
          },
          {
            headerName: 'Protection products',
            field: 'warranties_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'protectionProduct',
            sortable: true,
            width: 102,
            comparator: gridBadgeComparator,
            headerTooltip: 'Protection products',
            resizable: true,
          },
          {
            headerName: 'Unused redemptions',
            field: 'unredeemed_badge',
            cellRenderer: 'badgesRenderer',
            colId: 'unusedRedemptions',
            sortable: true,
            width: 90,
            comparator: gridBadgeComparator,
            headerTooltip: 'Unused redemptions',
            resizable: true,
          },
          {
            headerName: 'Age Inventory',
            field: 'inventory_vehicle',
            cellRenderer: 'badgesRenderer',
            colId: 'ageInventory',
            sortable: true,
            width: 90,
            comparator: gridBadgeComparator,
            headerTooltip: 'Age Inventory',
            resizable: true,
          },
        ],
      },
      {
        children: [
          {
            headerName: 'ASA',
            field: 'asa',
            sortable: true,
            width: 75,
            filter: 'agTextColumnFilter',
            cellRenderer: 'textRenderer',
            filterParams: { comparator: gridTextToNumberComparator },
            cellStyle: (params) => {
              if (this.asaPermissions.includes(params.value)) {
                return { color: this.props.theme.hendrick.asaIndicators.currentAsaColor };
              }
              return null;
            },
            valueGetter: (params) => {
              return completeEmptyOrNullFields(params.data.asa);
            },
            headerTooltip: 'ASA',
            resizable: true,
          },
          {
            headerName: 'Store Name',
            field: 'inventory_vehicle.DealerId',
            filter: true,
            sortable: true,
            cellRenderer: 'textRenderer',
            width: 90,
            valueGetter: (params) => {
              return completeEmptyOrNullFields(params.data.inventory_vehicle.DealerId);
            },
            headerTooltip: 'Store Name',
            resizable: true,
          },
          {
            headerName: 'Date lead',
            field: 'last_activity_datetime',
            sortable: true,
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => displayDatetimeFormatter(value),
            filterParams: {
              comparator: gridDatetimeFilterComparator,
            },
            width: 103,
            comparator: gridDatetimeSortComparator,
            headerTooltip: 'Date lead',
            resizable: true,
          },
        ],
      },
    ];
  };

  render() {
    const { classes, data } = this.props;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.paper}>
            <div style={{ height: 520, width: '100%' }} className={GlobalState.AppComponent.state.gridModeClass}>
              {data && (
                <AgGridReact
                  ref={(c) => (this.dataGrid = c)}
                  paginationAutoPageSize
                  rowData={data}
                  columnDefs={this.columnDefinitions}
                  content={{ componentParent: this }}
                  rowSelection="single"
                  frameworkComponents={this.frameworkComponents}
                  pagination
                  rowHeight={38}
                  headerHeight={40}
                  onFirstDataRendered={this.onFirstDataRendered}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const RankingViewTemplate = withStyles(styles)(withTheme(RankingViewTemplateComponent));
