/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './assets/styles/ag-grid-theme-modes.scss';
import { SessionDialog } from './components/molecules';
import { PermissionsMediator } from './lib/storageMediators/PermissionsMediator';
import { deleteCookie } from './lib/operators/deleteCookie';

import { LeadViewPage, LeadSearchPage, SignInPage, RankingViewPage, RankingByDatePage } from './components/pages';

import { ProtectedRoute } from './components/atoms';
import NotFound from './components/pages/Errors/404';
import { GlobalState } from './GlobalState';
import { buildLight, buildDark } from './assets/styles/theme';
import { SnackbarVisitor } from './lib/snackbar/SnackbarVisitor';
import { API } from './lib/xhr';

const lightTheme = 'light';
const darkTheme = 'dark';
const gridLightClass = 'ag-theme-material';
const gridDarkClass = 'ag-theme-material-dark';
let idleExpirationTimer;
let sessionExpirationTimer;
export class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      theme: buildDark(),
      themeName: darkTheme,
      gridModeClass: gridDarkClass,
      displayExpirationDialog: false,
    };

    GlobalState.AppComponent = this;
    this.notifier = new SnackbarVisitor(props);
    this.api = new API(this.notifier);
  }

  buildTheme = (name) => {
    if (name === lightTheme) {
      return buildLight();
    }

    return buildDark();
  };

  switchTheme = () => {
    const currentTheme = this.state.themeName;
    const nextThemeName = currentTheme === lightTheme ? darkTheme : lightTheme;
    const nextTheme = this.buildTheme(nextThemeName);
    const nextGridModeClass = nextThemeName === lightTheme ? gridLightClass : gridDarkClass;
    this.setState({
      theme: nextTheme,
      themeName: nextThemeName,
      gridModeClass: nextGridModeClass,
    });
  };

  timer = (duration) => {
    return 1000 * 60 * duration;
  };

  startSessionTimer = () => {
    if (idleExpirationTimer) clearTimeout(idleExpirationTimer);
    if (sessionExpirationTimer) clearTimeout(sessionExpirationTimer);

    sessionExpirationTimer = setTimeout(
      () => this.handleSessionIdle(),
      this.timer(process.env.REACT_APP_SESSION_EXPIRE_NOTICE)
    );
  };

  handleSessionRefresh = () => {
    this.setState({ displayExpirationDialog: false });
    this.api.request
      .get(`session/keepalive`)
      .success(() => this.startSessionTimer())
      .go();
  };

  performSignOut = () => {
    this.setState({ displayExpirationDialog: false });
    clearTimeout(sessionExpirationTimer);
    PermissionsMediator.clearData();
    deleteCookie('auth');
    if (GlobalState.History in window) {
      GlobalState.History.push('/sign-in');
    } else {
      window.location.href = `http://${window.location.hostname}:${window.location.port}/#/sign-in`;
    }
  };

  handleSessionIdle = () => {
    this.setState({ displayExpirationDialog: true });
    idleExpirationTimer = setTimeout(
      () => this.performSignOut(),
      this.timer(process.env.REACT_APP_SESSION_EXPIRE_AFTER_NOTICE)
    );
  };

  render() {
    const { theme, displayExpirationDialog } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <HashRouter>
            <SessionDialog isOpen={displayExpirationDialog} />
            <Container component="main" maxWidth="xl">
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/sign-in" />} />
                <Route path="/sign-in" component={SignInPage} />
                <ProtectedRoute exact path="/ranking" permission="VIEW_RANKING" component={RankingViewPage} />
                <ProtectedRoute exact path="/lead/:id" permission="VIEW_LEADVIEW" component={LeadViewPage} />
                <ProtectedRoute
                  path="/search/:selectedSearch"
                  permission="VIEW_LEADSEARCH"
                  component={LeadSearchPage}
                />
                <ProtectedRoute path="/ranking/:dateLabel" permission="VIEW_RANKING" component={RankingByDatePage} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </HashRouter>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
