import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Link,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
} from '@material-ui/core';
import {
  Poll as PollIcon,
  Pageview as PageviewTwoTone,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  AccountCircle as AccountCircleIcon,
  BrightnessMedium as BrightnessMediumIcon,
} from '@material-ui/icons';
import { ReactComponent as FontLogo } from '../../assets/images/brand/Hendricks_logo.svg';
import { PermissionsMediator } from '../../lib/storageMediators/PermissionsMediator';
import { GlobalState } from '../../GlobalState';
import { DailyIcon, WeeklyIcon, MonthlyIcon } from '../atoms';
import { SessionDialog } from '../molecules';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: `${1} !important`,
    display: 'flex',
    '& .MuiListItemIcon-root': {
      minWidth: '47px',
      alignItems: 'center',
    },
  },
  accountMenu: {
    marginTop: 32,
  },
  menuButton: {
    marginRight: 36,
  },
  rightButtons: {
    marginLeft: 'auto',
  },
  headerIcons: {
    fill: theme.palette.primary.white,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  drawerList: { color: theme.palette.primary.contrastText },
  drawerIcon: { color: theme.palette.primary.contrastIcon },
  toolbar: {
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.white,
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    flex: 1,
  },
  fontLogo: {
    width: '120px',
  },
  tooltip: {
    width: '100%',
  },
  itemContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    padding: 1,
  },
}));

function NavigationDrawer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAccountMenuClick = (evt) => {
    setAnchorEl(evt.currentTarget);
    setAccountMenuOpen(true);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
    setAccountMenuOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showByPermission = (permissions) => PermissionsMediator.has(permissions);

  return (
    <div className={classes.root}>
      <SessionDialog />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.header}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon className={classes.headerIcons} />
          </IconButton>
          <Link color="inherit" href="/#/ranking">
            <FontLogo className={classes.fontLogo} />
          </Link>
          <div className={classes.rightButtons}>
            <IconButton edge="end" color="inherit" aria-label="mode" onClick={GlobalState.AppComponent.switchTheme}>
              <BrightnessMediumIcon className={classes.headerIcons} />
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="open account menu"
              aria-controls="account-menu"
              onClick={handleAccountMenuClick}
              edge="end"
            >
              <AccountCircleIcon className={classes.headerIcons} />
            </IconButton>
          </div>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            className={classes.accountMenu}
            open={accountMenuOpen}
            onClose={handleAccountMenuClose}
          >
            <MenuItem
              component={Link}
              target="_blank"
              href="https://trafficbuddy.hendrickautoguard.com/usersupport.html"
              className={classes.drawerList}
            >
              Help/Support
            </MenuItem>
            <Divider />
            <MenuItem onClick={GlobalState.AppComponent.performSignOut}> Sign Out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{ elevation: 10 }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={{ fill: 'white' }} />{' '}
          </IconButton>
        </div>
        <List>
          {showByPermission('VIEW_RANKING') && (
            <ListItem component={Link} href="/#/ranking" button key="Ranking">
              <Tooltip className={classes.tooltip} title="Propensity Ranking" arrow>
                <div className={classes.itemContainer}>
                  <ListItemIcon>
                    <PollIcon className={classes.drawerIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Propensity Ranking" className={classes.drawerList} />
                </div>
              </Tooltip>
            </ListItem>
          )}
          {showByPermission('VIEW_LEADSEARCH') && (
            <ListItem component={Link} href="/#/search/lead" button key="Search">
              <Tooltip className={classes.tooltip} title="New Search" arrow>
                <div className={classes.itemContainer}>
                  <ListItemIcon>
                    <PageviewTwoTone className={classes.drawerIcon} />
                  </ListItemIcon>
                  <ListItemText primary="New Search" className={classes.drawerList} />
                </div>
              </Tooltip>
            </ListItem>
          )}
        </List>
        <Divider />
        {showByPermission('VIEW_RANKING') && (
          <List className={classes.drawerList}>
            <ListItem component={Link} href="/#/ranking/day" button key="Daily">
              <Tooltip className={classes.tooltip} title="Leads of the Day" arrow>
                <div className={classes.itemContainer}>
                  <ListItemIcon>
                    <DailyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leads of the Day" className={classes.drawerList} />
                </div>
              </Tooltip>
            </ListItem>
            <ListItem component={Link} href="/#/ranking/week" button key="Weekly">
              <Tooltip className={classes.tooltip} title="Leads of the last 7 days" arrow>
                <div className={classes.itemContainer}>
                  <ListItemIcon>
                    <WeeklyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leads of the last 7 days" className={classes.drawerList} />
                </div>
              </Tooltip>
            </ListItem>
            <ListItem component={Link} href="/#/ranking/month" button key="Monthly">
              <Tooltip className={classes.tooltip} title="Leads of the Month" arrow>
                <div className={classes.itemContainer}>
                  <ListItemIcon>
                    <MonthlyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leads of the Month" className={classes.drawerList} />
                </div>
              </Tooltip>
            </ListItem>
          </List>
        )}
      </Drawer>
    </div>
  );
}

const NavigationDrawerRouter = withRouter(NavigationDrawer);

export { NavigationDrawerRouter as NavigationDrawer };
