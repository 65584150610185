import React from 'react';
import { VehicleBadgeGreyed, VehicleBadgeOther, VehicleBadgeOwn } from '../atoms';
import { ChipBase } from './ChipBase';

const pickBadge = (vehicleCount, ownership) => {
  if (vehicleCount > 0 && ownership === 'MINE') return <VehicleBadgeOwn />;
  if (vehicleCount > 0 && ownership === 'OTHER') return <VehicleBadgeOther />;

  return <VehicleBadgeGreyed />;
};

const VehicleChip = (props) => {
  const component = pickBadge(props.count, props.ownership);
  return ChipBase(props, component);
};
export { VehicleChip };
