import { parseDatetimeForView } from './parseDatetimeForView';

// eslint-disable-next-line no-unused-vars
export function gridDatetimeSortComparator(valueA, valueB, nodeA, nodeB, isInverted) {
  const a = parseDatetimeForView(valueA);
  const b = parseDatetimeForView(valueB);

  if (a === null && b === null) return 0;

  if (isInverted) {
    if (a === null) return -1;
    if (b === null) return 1;
  } else {
    if (a === null) return 1;
    if (b === null) return -1;
  }

  return a.getTime() - b.getTime();
}
