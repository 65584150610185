import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    color: theme.hendrick.inMarketIndicator,
  },
}));

const RankingNumberCellRenderer = (props) => {
  const classes = useStyles();
  const className = props.data.is_in_market ? classes.content : null;

  return <Box className={className}>{props.value}</Box>;
};

export { RankingNumberCellRenderer };
