import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { LogoWithFlag } from './LogoWithFlag';

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <LogoWithFlag {...props} />
    </Box>
  );
};

export { Logo };
