import React, { PureComponent } from 'react';
import { Grid, Divider, withStyles } from '@material-ui/core';
import { FolderShared as FolderSharedIcon, PersonPin as PersonPinIcon } from '@material-ui/icons';
import { LeadContactInfo, ProfilingCard } from '../molecules';
import {
  VehicleSalesGridOrganism,
  ServiceLaneGridOrganism,
  EleadsFeedGridOrganism,
  IntenderRecordsGridOrganism,
  HouseholdAndGarageOrganism,
  ProtectionProductsGridOrganism,
  UnredeemedProductsGridOrganism,
} from '../organisms';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  summaryDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class LeadViewTemplateComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
    };
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  handleItemClick = (panel) => () => {
    this.setState({ expanded: panel });
    const element = document.querySelector(`#${panel}`);
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  prepSales = (sales) => {
    const output = sales.map((s) => {
      const {
        DealDate,
        Vehicle,
        Price: VehiclePrice,
        GrossProfit: VehicleGrossProfit,
        Transaction,
        Salespersons,
        Dealership,
        DealershipOwnership,
      } = s;
      const VehicleSummary = [Vehicle.Make, Vehicle.Model, Vehicle.Year, Vehicle.VehicleDescription]
        .filter((x) => !!x)
        .join(' ');

      let SalespersonFullname = null;
      if (Salespersons && Salespersons.length) {
        SalespersonFullname = `${Salespersons[0].FirstName} ${Salespersons[0].LastName}`;
      }

      const model = {
        DealDate,
        VehicleSummary,
        VehicleDescription: Vehicle.ModelDescription,
        VehiclePrice,
        VehicleGrossProfit,
        TotalPayment: Transaction.TotalOfPayments,
        LoanTermination: Transaction.LoanTerminationDate,
        FinancedAmount: Transaction.FinancedAmount,
        SoldBy: SalespersonFullname,
        DealershipName: Dealership.Name,
        SalespersonFullname,
        DealershipOwnership,
      };

      return model;
    });

    return output;
  };

  render() {
    const { expanded } = this.state;

    const {
      classes,
      lead,
      sales,
      salesProfiling,
      serviceLane,
      eleadsFeed,
      eleadFeedProfiling,
      intenderProfiling,
      intenderRecords,
      householdAndGarage,
      badges,
      serviceLaneProfiling,
      protectionProducts,
      protectionProductsProfiling,
      unredeemedProducts,
      unredeemedProductsProfiling,
    } = this.props;
    const vehicleSales = this.prepSales(sales);

    const summaryUnion = [
      ...salesProfiling.Summary,
      ...serviceLaneProfiling.Summary,
      ...protectionProductsProfiling.Summary,
      ...unredeemedProductsProfiling.Summary,
    ];
    const marketInsightsUnion = [...eleadFeedProfiling.MarketInsights, ...intenderProfiling.MarketInsights];

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <LeadContactInfo lead={lead} badges={badges} handleItemClick={this.handleItemClick} />
          </Grid>
          <Grid item xs={4}>
            <ProfilingCard items={summaryUnion} title="Hendrick Relationships Summary">
              <FolderSharedIcon />
            </ProfilingCard>
          </Grid>
          <Grid item xs={4}>
            <ProfilingCard items={marketInsightsUnion} title="Market data insights">
              <PersonPinIcon />
            </ProfilingCard>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="vehicleSalesPanel">
              <VehicleSalesGridOrganism
                data={vehicleSales}
                expanded={expanded === 'vehicleSalesPanel'}
                onChange={this.handleChange('vehicleSalesPanel')}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div id="serviceLanePanel">
              <ServiceLaneGridOrganism
                data={serviceLane}
                expanded={expanded === 'serviceLanePanel'}
                onChange={this.handleChange('serviceLanePanel')}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div id="protectionProductsPanel">
              <ProtectionProductsGridOrganism
                data={protectionProducts}
                expanded={expanded === 'protectionProductsPanel'}
                onChange={this.handleChange('protectionProductsPanel')}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div id="unredeemedProductsPanel">
              <UnredeemedProductsGridOrganism
                data={unredeemedProducts}
                expanded={expanded === 'unredeemedProductsPanel'}
                onChange={this.handleChange('unredeemedProductsPanel')}
              />
            </div>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12}>
            <EleadsFeedGridOrganism data={eleadsFeed} />
          </Grid>
          <Grid item xs={6}>
            <IntenderRecordsGridOrganism data={intenderRecords} />
          </Grid>
          <Grid item xs={6}>
            <HouseholdAndGarageOrganism data={householdAndGarage} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export const LeadViewTemplate = withStyles(styles)(LeadViewTemplateComponent);
